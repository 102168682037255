<template>
  <div class="patient-section-tabs">
    <ul
      v-if="patient.user_id"
      class="nav nav-tabs"
    >
      <li
        v-for="tab in displayedTabs"
        :key="tab.name"
        class="nav-item"
        @click="SET_PATIENT_ACTIVE_TAB(tab.name)"
      >
        <router-link
          v-if="patient.assignment_id && patient.ccauId"
          :to="{ name: tab.route, params: {ccaId: $getEncodedId(patient.assignment_id), ccauId: $getEncodedId(patient.ccauId)} }"
          class="nav-link"
        >
          <span>{{ tab.label }}</span>
          <span
            v-if="tab.submenu"
            class="caret"
            @click="tab.submenu.open=!tab.submenu.open"
          >
            <i class="fas fa-caret-down" />
          </span>
        </router-link>
        <div
          v-if="tab.submenu && tab.submenu.open"
          class="dropdown"
        >
          OPTIONS
        </div>
      </li>
    </ul>
  </div>
</template>

<style>

</style>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'

export default {
  name: 'ClinicianPatientNav',
  components: {},
  mixins: [Helpers, UserManagementComponentMixin],
  props: ['ccauId'],
  data () {
    return {
      tabs: [
        {
          label: 'Measures',
          name: 'measures',
          route: 'ClinicianPatientMeasures'
        },
        {
          label: 'List',
          name: 'list',
          route: 'ClinicianPatientMeasuresList'
        },
        {
          label: 'Alerts',
          name: 'alerts',
          route: 'ClinicianPatientAlerts'
        },
        {
          label: 'Information',
          name: 'information',
          route: 'ClinicianPatientInformation'
        },
        {
          label: 'Insurance',
          name: 'insurance',
          route: 'ClinicianPatientInsurance'
        },
        {
          label: 'Programs',
          name: 'programs',
          route: 'ClinicianPatientPrograms'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeTab: 'getPatientActiveTab',
      patient: 'getActiveAssignment'
    }),
    displayedTabs () {
      return this.tabs.filter((tab) => this.shouldDisplayTab(tab))
    },
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    insuranceEnabled () {
      return this.$store.getters.generalSettings.enable_insurance_tab
    },
    isPatient () {
      return Number(this.patient.patient_relation_id) === 1
    }
  },
  methods: {
    ...mapActions([
      'SET_PATIENT_ACTIVE_TAB'
    ]),
    shouldDisplayTab (tab) {
      if (tab.name === 'programs' && (!this.isPatient || !this.programsEnabled)) {
        return false
      }
      if (tab.name === 'insurance' && (!this.isPatient || !this.insuranceEnabled)) {
        return false
      }
      return true
    }
  }
}
</script>
