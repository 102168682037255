<template>
  <div
    v-if="!loading && activeAssignment && activeAssignment.ccauId === ccauId"
    class="patient-view"
  >
    <ClinicianPatientAssignmentsTabs :cca-id="cca_id" />
    <ClinicianPatientHeader
      v-if="isPatient"
      @openLastSessionDetail="openPatientLastSessionDetail"
    />
    <ClinicianRespondentHeader
      v-if="!isPatient"
      :key="ccauId"
      :respondent="activeAssignment"
      @openLastSessionDetail="openPatientLastSessionDetail"
    />
    <ClinicianPatientNav
      :cca-id="cca_id"
      :first-name="activeAssignment.first_name"
    />

    <div class="patient-view-content">
      <router-view />
    </div>
    <overlay
      v-if="lastSessionDetail"
      position="right"
      @close="closeLastSessionDetail"
    >
      <div slot="content">
        <LastOwlSessionDetail
          :patient="lastSessionDetail"
          :mutation-text="'patient'"
          @updateLastSession="updateLastSession"
        />
      </div>
    </overlay>
  </div>
</template>

<script>
import ClinicianPatientAssignmentsTabs from './ClinicianPatientAssignmentsTabs'
import ClinicianPatientHeader from './ClinicianPatientHeader'
import ClinicianRespondentHeader from './ClinicianRespondentHeader'
import ClinicianPatientNav from './ClinicianPatientNav'
import Overlay from '@/components/clinician/Overlay'
import LastOwlSessionDetail from '@/components/common/LastOwlSessionDetail'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'ClinicianPatient',
  components: {
    ClinicianPatientAssignmentsTabs,
    ClinicianPatientHeader,
    ClinicianRespondentHeader,
    ClinicianPatientNav,
    LastOwlSessionDetail,
    Overlay
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.$store.dispatch('SET_SINGLE_PATIENT_REFERRER_ROUTE', from.name)
      vm.from = from
    })
  },
  mixins: [Helpers, LastSessionDetail],
  data: () => {
    return {
      cca_id: null,
      ccauId: null,
      loading: false,
      patientId: null,
      ccau_id: null,
      respondentId: null,
      plusOpen: false,
      addRespondentOpen: false,
      backRoute: ''
    }
  },
  computed: {
    ...mapGetters({
      activeSection: 'getPatientActiveTab',
      activeAssignment: 'getActiveAssignment',
      assignments: 'getAssignments'
    }),
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    isPatient () {
      return this.activeAssignment.patient_relation_id === 1
    }
  },
  watch: {
    '$route.params' (to) {
      this.cca_id = this.$getDecodedId(this.$route.params.ccaId)
      this.ccauId = parseInt(this.$getDecodedId(this.$route.params.ccauId))
      this.setActiveAssignment()
    }
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
    this.cca_id = this.$getDecodedId(this.$route.params.ccaId)
    this.ccauId = parseInt(this.$getDecodedId(this.$route.params.ccauId))
    this.closeLastSessionDetail()
  },
  mounted () {
    this.getAssignments()
  },
  beforeDestroy () {
    this.$store.commit('user/SET_COMING_FROM_PROGRAM', null)
    this.$store.commit('user/SET_DETAIL_TO_OPEN', null)
  },
  methods: {
    updateLastSession () {
      const measureId = this.$getDecodedId(this.$route.params.measureId)
      const ccauId = this.$getDecodedId(this.$route.params.ccauId)
      if (ccauId) {
        this.$store.dispatch('GET_PATIENTS_MEASURES', ccauId)
        if (measureId) {
          this.$store.dispatch('GET_PATIENT_SINGLE_MEASURE', { ccauId, measureId })
          this.$store.dispatch('GET_MEASURE_QUESTIONS', measureId)
        }
      }
    },
    getAssignments () {
      const loader = this.$loading.show()
      this.loading = true
      this.$store.dispatch('GET_ASSIGNMENTS', this.cca_id).then(() => {
        this.setActiveAssignment()
      }).catch(() => {
        this.$router.push({ name: 'PageNotFound' })
      }).finally(() => {
        this.loading = false
        loader.hide()
      })
    },
    setActiveAssignment () {
      this.$store.dispatch('SET_ASSIGNMENT_VIEW', this.ccauId).then(() => {
        this.patientId = this.$getEncodedId(this.activeAssignment.user_id)
      })
    },
    handleClickOutside (event) {
      const plusMenu = this.$refs.plusMenu
      const target = event.target

      if (plusMenu && plusMenu !== target && !plusMenu.contains(target)) {
        this.plusOpen = false
      }
    }
  }
}
</script>
<style lang="scss">
  .patient-view {
    padding-top: 0 !important;
  }
  .button-pdf a {
  color: #0A3560 !important;
  z-index: 200;
  cursor: pointer;
  outline: none;
  i {
    font-size: 1.1rem;
  }
}
.button-pdf a:hover {
  color: #2068AC !important;
}
.button-pdf a.disabled {
  color: #B3C1D1 !important;
  text-decoration: none;
}
.button-pdf a.disabled, .button-pdf a.failed {
  cursor: default;
}
.fa-exclamation-triangle {
  color: #F5DC23 !important;
  font-weight: 600 !important;
}
td .pdf-download {
  width: 42px;
}
</style>
