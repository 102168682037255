<template>
  <div class="patient-respondent-tabs">
    <router-link
      v-for="respondent in team"
      :key="respondent.id"
      :class="{ 'removed': respondent.deleted }"
      class="tab__item"
      :to="{ name: 'ClinicianPatient', params: {ccaId: $getEncodedId(respondent.assignment_id), ccauId: $getEncodedId(respondent.ccauId)} }"
    >
      <span class="tab__item-type">{{ respondent.patient_relation }}</span>
      <span class="tab__item-name">{{ respondent.full_name }}
        <span
          v-if="respondent.deleted === true"
          class="badge badge-info"
        >{{ $t("removedFromTeamTag") }}</span>
        <span
          v-if="respondent.status === &quot;inactive&quot;"
          class="badge badge-primary"
        >{{ $t("inactive") }}</span>
      </span>
    </router-link>
    <router-link
      :to="returnRoute"
      class="close-patient-view"
    >
      <i class="fal fa-times" />
    </router-link>
  </div>
</template>

<style>

</style>
<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'

export default {
  name: 'ClinicianPatientAssignmentsTabs',
  components: {},
  mixins: [Helpers, UserManagementComponentMixin],
  props: ['ccaId'],
  computed: {
    ...mapGetters({
      team: 'getAssignments',
      backRoute: 'getSinglePatientReferrerRoute'
    }),
    returnRouteName () {
      const allowedRoutes = ['ClinicianHomepage', 'ClinicianPatientsList', 'ClinicianRespondentsList']
      if (!allowedRoutes.includes(this.backRoute)) {
        return this.getNextRouteWhenGoingHome()
      }

      return this.backRoute
    },
    returnRoute () {
      // If there's a search query param, pass it thru.  Otherwise just pass the route name.
      if (this.$route.query.search) {
        return {
          name: this.returnRouteName,
          query: {
            search: this.$route.query.search
          }
        }
      }
      return { name: this.returnRouteName }
    }
  }
}
</script>
