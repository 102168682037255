<template>
  <div class="patient-header">
    <div
      class="patient"
      :class="{inactive: inactive}"
    >
      <div class="patient__info">
        <initials-circle
          :first="firstLetter"
          :second="secondLetter"
          inverse
          first-id="navbar-outer-circle"
          second-id="navbar-inner-circle"
        />
      </div>
      <div class="patient__info">
        <span class="patient__info-label">{{ $t('relationToPatient') }}</span>
        <span class="patient__info-value">{{ patient.patient_relation }}</span>
      </div>
      <div class="patient__info">
        <span class="patient__info-label">{{ $t('respondentType') }}</span>
        <span class="patient__info-value">{{ respondentType }}</span>
      </div>
      <div class="patient__info">
        <span class="patient__info-label">{{ $t('owlId') }}</span>
        <span class="patient__info-value">{{ patient.user_id }}</span>
      </div>
    </div>
    <div class="right-container">
      <div
        v-if="showLatestCompletedSession"
        class="patient__info"
      >
        <span class="patient__info-label">LAST COMPLETED</span>
        <span
          class="patient__info-value last-completed-session"
          @click="$emit('openLastSessionDetail', latestSession.complete)"
        >
          <LatestCompletedSessionItem
            :data="latestSession.complete"
            source="patientDetail"
          />
        </span>
      </div>
      <div
        v-if="ShowNextSession"
        class="patient__info"
      >
        <span class="patient__info-label">NEXT DUE</span>
        <span
          class="patient__info-value next-session"
          @click="$emit('openLastSessionDetail', latestSession.pending)"
        >
          <NextSessionDueItem
            :data="latestSession.pending"
            source="patientDetail"
            :one-line="true"
          />
        </span>
      </div>
      <SignInPatientButton
        :key="patient.user_id"
        :user="patient"
      />
    </div>
  </div>
</template>

<style>

</style>
<script>
import InitialsCircle from '@/components/common/InitialsCircle'
import { mapGetters } from 'vuex'
import { PatientHelper } from '@/mixins/PatientHelper'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { INACTIVE_USER_STRING } from '@/store/modules/user/constants.js'
import LatestCompletedSessionItem from '@/components/common/LatestCompletedSessionItem'
import NextSessionDueItem from '@/components/common/NextSessionDueItem'
import SignInPatientButton from '@/components/clinician/patients/SignInPatientButton'

export default {
  name: 'ClinicianRespondentHeader',
  components: {
    LatestCompletedSessionItem,
    SignInPatientButton,
    NextSessionDueItem,
    InitialsCircle
  },
  mixins: [PatientHelper, UserManagementComponentMixin],
  props: ['respondent'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      patient: 'getActiveAssignment',
      loadingSchedulingOptions: 'getLoadingSchedulingOptions',
      latestSession: 'getRespondentLatestSession',
      patientPrograms: 'getSinglePatientPrograms'
    }),
    firstLetter () {
      return this.patient.first_name ? this.patient.first_name.substring(0, 1) : ''
    },
    secondLetter () {
      return this.patient.last_name ? this.patient.last_name.substring(0, 1) : ''
    },
    respondentType () {
      switch (this.patient.respondent_type) {
        case 'adult':
          return this.$t('respondentTypeAdult')
        case 'Parent':
          return this.$t('respondentTypeParent')
        case 'Teacher':
          return this.$t('respondentTypeTeacher')
        default:
          return ''
      }
    },
    showLatestCompletedSession () {
      return this.latestSession && this.latestSession.complete
    },
    ShowNextSession () {
      return this.latestSession && this.latestSession.pending
    },
    inactive () {
      const status = this.patient && this.patient.status ? this.patient.status : ''
      return status === INACTIVE_USER_STRING || this.loading || this.logingIn || this.loadingSchedulingOptions
    },
    partOfTeam () {
      return !this.patient.deleted
    },
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    shouldGetPatientPrograms () {
      return this.programsEnabled && (!this.patientPrograms || !this.patientPrograms.length || Number(this.patientPrograms[0].access_control_id) !== Number(this.respondent.patient_id))
    }
  },
  created () {
    this.loading = true
    if (this.shouldGetPatientPrograms && this.respondent.patient_id) {
      this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAMS', this.respondent.patient_id).finally(() => {
        this.getRespondentLatestSession().finally(() => {
          this.loading = false
        })
      })
    } else {
      this.getRespondentLatestSession().finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
