<template>
  <div class="patient-header">
    <div
      class="patient"
      :class="{inactive: inactive}"
    >
      <div class="patient__info">
        <initials-circle
          :first="firstLetter"
          :second="secondLetter"
          :inactive="inactive"
          inverse
          :alert="alerts.length"
          first-id="navbar-outer-circle"
          second-id="navbar-inner-circle"
        />
      </div>
      <div class="patient__info">
        <span class="patient__info-label">{{ $t('age') }}</span>
        <span class="patient__info-value">{{ age }}</span>
      </div>
      <div class="patient__info">
        <span class="patient__info-label">{{ $t('mrn') }}</span>
        <truncated-text
          class="patient__info-value"
          :text-limit="10"
          :show-tooltip="mrn.length > 10"
          :text="mrn"
        />
      </div>
      <div class="patient__info">
        <span class="patient__info-label">{{ $t('owlId') }}</span>
        <span class="patient__info-value">{{ patient.user_id }}</span>
      </div>
      <div
        v-if="programsEnabled"
        class="patient__info"
      >
        <span class="patient__info-label">{{ $tc('programs', activePatientProgramsAssignation.length > 1 ? 2 : 1) }}</span>
        <span class="patient__info-value programs">{{ programs || '-' }}</span>
      </div>
    </div>
    <div class="right-container">
      <div
        v-if="showLatestCompletedSession"
        class="patient__info"
      >
        <span class="patient__info-label">{{ $t('lastCompleted') }}</span>
        <span
          class="patient__info-value last-completed-session"
          @click="$emit('openLastSessionDetail', latestSession.complete)"
        >
          <LatestCompletedSessionItem
            :data="latestSession.complete"
            source="patientDetail"
          />
        </span>
      </div>
      <div
        v-if="ShowNextSession"
        class="patient__info"
      >
        <span class="patient__info-label">{{ $t('nextDue') }}</span>
        <span
          class="patient__info-value next-session"
          @click="$emit('openLastSessionDetail', latestSession.pending)"
        >
          <NextSessionDueItem
            :data="latestSession.pending"
            source="patientDetail"
            :one-line="true"
          />
        </span>
      </div>
      <SignInPatientButton
        :key="patient.user_id"
        :user="patient"
      />
    </div>
  </div>
</template>

<style>

</style>
<script>
import moment from 'moment'
import InitialsCircle from '@/components/common/InitialsCircle'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import lastSesionStatusText from '@/data/lastSesionStatusText'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { INACTIVE_USER_STRING } from '@/store/modules/user/constants.js'
import LatestCompletedSessionItem from '@/components/common/LatestCompletedSessionItem'
import NextSessionDueItem from '@/components/common/NextSessionDueItem'
import SignInPatientButton from '@/components/clinician/patients/SignInPatientButton'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import TruncatedText from '@/components/common/TruncatedText'

export default {
  name: 'ClinicianPatientHeader',
  components: {
    TruncatedText,
    InitialsCircle,
    LatestCompletedSessionItem,
    SignInPatientButton,
    NextSessionDueItem
  },
  mixins: [Helpers, PatientHelper, DateTimeHelper, UserManagementComponentMixin],
  data () {
    return {
      loading: false,
      loadingPatient: false,
      lastSesionStatusText: lastSesionStatusText
    }
  },
  computed: {
    ...mapGetters({
      loadingSchedulingOptions: 'getLoadingSchedulingOptions',
      patient: 'getActiveAssignment',
      latestSession: 'getRespondentLatestSession',
      patientData: 'getSinglePatient',
      patientAlerts: 'getAlerts',
      loadingPrograms: 'getSinglePatientProgramsLoading'
    }),
    mrn () {
      return (this.patient.mrn) ? this.patient.mrn : '-'
    },
    alerts () {
      let filteres = []
      if (this.patientAlerts.length) {
        filteres = this.patientAlerts.filter(a => {
          return !a.acknowledged_data.acknowledged
        })
      }
      return filteres
    },
    age () {
      return (this.patient.birth_date) ? moment().diff(this.patient.birth_date, 'years') : '-'
    },
    firstLetter () {
      return this.patient.first_name ? this.patient.first_name.substring(0, 1) : ''
    },
    secondLetter () {
      return this.patient.last_name ? this.patient.last_name.substring(0, 1) : ''
    },
    showLatestCompletedSession () {
      return this.latestSession && this.latestSession.complete
    },
    ShowNextSession () {
      return this.latestSession && this.latestSession.pending
    },
    latestSessionTooltip () {
      const tooltipData = {
        placement: 'right',
        classes: ['no-arrow']
      }

      if (this.latestSession.status === 5) {
        tooltipData.content = this.$t('noMeasuresAreDue')
        return tooltipData
      }

      const text = this.latestSession.status === 2 ? 'Completed' : 'Due'
      const date = this.latestSession.status === 2 ? this.latestSession.completed_date : this.latestSession.date
      tooltipData.content = `${text} ${this.$toLocal(date, 'MMM DD, YYYY')}`

      return tooltipData
    },
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    programs () {
      if (this.loadingPrograms) {
        return null
      }
      const programsFiltered = []
      return this.activePatientProgramsAssignation.filter(program => {
        if (programsFiltered.indexOf(program.program_id) === -1) {
          programsFiltered.push(program.program_id)
          return true
        }
        return false
      }).map(program => program.program.name).join(', ')
    },
    inactive () {
      return this.patient.status.toLowerCase() === INACTIVE_USER_STRING || this.loadingPatient || this.loading || this.logingIn || this.loadingSchedulingOptions
    }
  },
  created () {
    if (this.programsEnabled) {
      this.loading = true
      this.loadingPatient = true
      this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAMS', this.patient.user_id).then(() => {
        this.getLatestSession()
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        this.loading = false
      })
      this.getPatient(this.patient.user_id).then((params) => {
        this.loadingPatient = false
      })
    } else {
      this.getLatestSession()
    }

    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
    this.$store.dispatch('GET_CLINICIAN_PATIENT_ALERTS', this.$getDecodedId(this.$route.params.ccauId))
  },
  destroyed () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
    this.$store.dispatch('RESET_PATIENT')
    this.$store.dispatch('RESET_LAST_SESSION')
    this.$store.dispatch('RESET_RESPONDENT_LAST_SESSION')
  },
  methods: {
    refreshView () {
      this.getLatestSession()
    },
    getLatestSession () {
      this.loading = true
      this.getRespondentLatestSession().then(() => {
        this.loading = false
      })
    }
  }
}
</script>
